var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"新建用户合同","width":780,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"用户","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'oa_user_id',
            {
              rules: [
                { required: true, message: '' },
              ],
            },
          ]),expression:"[\n            'oa_user_id',\n            {\n              rules: [\n                { required: true, message: '' },\n              ],\n            },\n          ]"}],attrs:{"option-label-prop":"tt","show-arrow":false,"filter-option":false,"not-found-content":null,"show-search":"","allow-clear":"","placeholder":"用户真实姓名","default-active-first-option":false},on:{"search":this.userSearch,"change":_vm.handleUserChange}},_vm._l((_vm.userList),function(d){return _c('a-select-option',{key:d.id,attrs:{"tt":d.name}},[_vm._v(" "+_vm._s(d.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"公司名称","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['company', {rules: [{required: true, message: '请输入'}, {max:128, message: '输入不符合长度限制'}]}]),expression:"['company', {rules: [{required: true, message: '请输入'}, {max:128, message: '输入不符合长度限制'}]}]"}],attrs:{"placeholder":"公司名称"}})],1),_c('a-form-item',{attrs:{"label":"合同始期","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['start_date', {rules: [{required: true, message: '请输入'}]}]),expression:"['start_date', {rules: [{required: true, message: '请输入'}]}]"}],attrs:{"show-time":"","format":"YYYY-MM-DD","placeholder":"合同始期","value-format":"yyyy-MM-DD"}})],1),_c('a-form-item',{attrs:{"label":"合同年限","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['contract_years', {initialValue: 1, rules: [{required: true, message: '请输入'}]}]),expression:"['contract_years', {initialValue: 1, rules: [{required: true, message: '请输入'}]}]"}],attrs:{"min":1,"placeholder":"合同年限"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
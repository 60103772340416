import request from '@/utils/request'

const oaEmployContractApi = {
  oaEmployContractList: '/oa/user_contract/',
  oaEmployContractCreate: '/oa/user_contract/',
  oaEmployContractUpdate: '/oa/user_contract/',
  oaEmployContractDelete: '/oa/user_contract/'
}

/**
 * 列表
 */
export function oaEmployContractList (parameter) {
  return request({
    url: oaEmployContractApi.oaEmployContractList,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function oaEmployContractCreate (parameter) {
  return request({
    url: oaEmployContractApi.oaEmployContractCreate,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function oaEmployContractUpdate (parameter, oa_user_contract_id) {
  return request({
    url: oaEmployContractApi.oaEmployContractUpdate + oa_user_contract_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function oaEmployContractDelete (oa_user_contract_id) {
  return request({
    url: oaEmployContractApi.oaEmployContractDelete + oa_user_contract_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

<template>
  <a-modal
    title="新建用户合同"
    :width="780"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-form-item label="用户" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            option-label-prop="tt"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            show-search
            allow-clear
            v-decorator="[
              'oa_user_id',
              {
                rules: [
                  { required: true, message: '' },
                ],
              },
            ]"
            placeholder="用户真实姓名"
            :default-active-first-option="false"
            @search="this.userSearch"
            @change="handleUserChange"
          >
            <a-select-option v-for="d in userList" :key="d.id" :tt="d.name">
              {{ d.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="公司名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
            v-decorator="['company', {rules: [{required: true, message: '请输入'}, {max:128, message: '输入不符合长度限制'}]}]"
            placeholder="公司名称"/>
        </a-form-item>
        <a-form-item label="合同始期" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-date-picker
            v-decorator="['start_date', {rules: [{required: true, message: '请输入'}]}]"
            show-time
            format="YYYY-MM-DD"
            placeholder="合同始期"
            value-format="yyyy-MM-DD"/>
        </a-form-item>
        <a-form-item label="合同年限" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number v-decorator="['contract_years', {initialValue: 1, rules: [{required: true, message: '请输入'}]}]" :min="1" placeholder="合同年限"/>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
// 表单字段
import debounce from 'lodash/debounce'
import { oaUserList } from '@/api/oa_user'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    this.userSearch = debounce(this.handleUserSearch, 800)
    return {
      userList: [],
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this)
    }
  },
  methods: {
    handleUserSearch (value) {
      oaUserList({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.userList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleUserChange (value) {
      if (value === undefined) {
        this.handleUserSearch(value)
      }
    }
  }
}
</script>

import request from '@/utils/request'

const oaUserApi = {
  oaUserList: '/oa/user/',
  oaUserCreate: '/oa/user/',
  oaUserUpdate: '/oa/user/',
  oaUserDelete: '/oa/user/',
  oaUserExport: '/oa/user/export/',
  oaUserDepartmentList: '/oa/user_department/',
  oaUserDepartmentCreate: '/oa/user_department/',
  oaUserDepartmentUpdate: '/oa/user_department/',
  oaUserDepartmentDelete: '/oa/user_department/',
  oaUserRoleList: '/oa/user_role/',
  oaUserRoleCreate: '/oa/user_role/',
  oaUserRoleUpdate: '/oa/user_role/',
  oaUserRoleDelete: '/oa/user_role/',
  oaUserRankList: '/oa/user_rank/',
  oaUserRankCreate: '/oa/user_rank/',
  oaUserRankUpdate: '/oa/user_rank/',
  oaUserRankDelete: '/oa/user_rank/'
}

/**
 * 列表
 */
export function oaUserList (parameter) {
  return request({
    url: oaUserApi.oaUserList,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function oaUserCreate (parameter) {
  return request({
    url: oaUserApi.oaUserCreate,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function oaUserUpdate (parameter, oa_user_id) {
  return request({
    url: oaUserApi.oaUserUpdate + oa_user_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function oaUserDelete (oa_user_id) {
  return request({
    url: oaUserApi.oaUserDelete + oa_user_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function exportUser () {
  return request({
    url: oaUserApi.oaUserExport,
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob'
  })
}

/**
 * 列表
 */
export function oaUserDepartmentList (parameter) {
  return request({
    url: oaUserApi.oaUserDepartmentList,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 添加
 */
export function oaUserDepartmentCreate (parameter) {
  return request({
    url: oaUserApi.oaUserDepartmentCreate,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function oaUserDepartmentUpdate (parameter, oa_user_role_id) {
  return request({
    url: oaUserApi.oaUserDepartmentUpdate + oa_user_role_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function oaUserDepartmentDelete (oa_user_role_id) {
  return request({
    url: oaUserApi.oaUserDepartmentDelete + oa_user_role_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 列表
 */
export function oaUserRoleList (parameter) {
  return request({
    url: oaUserApi.oaUserRoleList,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function oaUserRoleCreate (parameter) {
  return request({
    url: oaUserApi.oaUserRoleCreate,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function oaUserRoleUpdate (parameter, oa_user_role_id) {
  return request({
    url: oaUserApi.oaUserRoleUpdate + oa_user_role_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function oaUserRoleDelete (oa_user_role_id) {
  return request({
    url: oaUserApi.oaUserRoleDelete + oa_user_role_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 列表
 */
export function oaUserRankList (parameter) {
  return request({
    url: oaUserApi.oaUserRankList,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function oaUserRankCreate (parameter) {
  return request({
    url: oaUserApi.oaUserRankCreate,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function oaUserRankUpdate (parameter, oa_user_rank_id) {
  return request({
    url: oaUserApi.oaUserRankUpdate + oa_user_rank_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function oaUserRankDelete (oa_user_rank_id) {
  return request({
    url: oaUserApi.oaUserRankDelete + oa_user_rank_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" v-if="!queryParam.id">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="用户">
              <a-input v-model="queryParam.user_name" placeholder="请输入姓名" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="企业">
              <a-input v-model="queryParam.company" placeholder="请输入企业名称" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item label="合同期">
              <a-select v-model="queryParam.range" placeholder="请选择" allow-clear>
                <a-select-option :value="0">全部</a-select-option>
                <a-select-option :value="1">未到期</a-select-option>
                <a-select-option :value="2">已到期</a-select-option>
                <a-select-option :value="3">临近到期(3个月)</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :xs="6">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
          <a-col :md="2" :xs="6" v-if="false">
            <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :pageNum="pageNum"
      :data="loadData"
      :scroll="{ x: 1000 }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 160px">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical"/>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '../modules/CreateForm'

import { oaEmployContractCreate, oaEmployContractDelete, oaEmployContractList } from '@/api/oa_contract'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      pageNum: 1,
      dataSource: [],
      loading: false,
      confirmLoading: false,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          fixed: 'left',
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '用户',
          dataIndex: 'user',
          width: 200,
          ellipsis: true
        },
        {
          title: '企业',
          dataIndex: 'company',
          width: 200,
          ellipsis: true
        },
        {
          title: '合同始期',
          width: 180,
          align: 'right',
          dataIndex: 'start_date'
        },
        {
          title: '合同结束',
          dataIndex: 'end_date',
          width: 180,
          align: 'right'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 180,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }

      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return oaEmployContractList(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.setCurrentPage()
  },
  methods: {
    setCurrentPage () {
      // const page = sessionStorage.getItem('CurrentPage')
      const page = this.$store.getters.pop
      if (page) {
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        } else {
          this.queryParam = {}
          this.$store.dispatch('clear')
        }
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleDelete (record) {
      oaEmployContractDelete(record.id).then((res) => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      form.validateFields((errors, values) => {
        if (!errors) {
          values.end_date = this.calculateEndDate(values.start_date, values.contract_years)
          this.confirmLoading = true
          oaEmployContractCreate(values).then(res => {
            this.visible = false
            this.confirmLoading = false
            if (res.code === 1000) {
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    calculateEndDate (startDate, years) {
      // 将开始时间转换为 Date 对象
      const start = new Date(startDate)
      // 计算结束时间
      const end = new Date(start)
      end.setFullYear(start.getFullYear() + years)

      // 格式化结束时间为 YYYY-MM-DD
      const year = end.getFullYear()
      const month = String(end.getMonth() + 1).padStart(2, '0') // 月份从 0 开始，需要加 1
      const day = String(end.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    }
  }
}
</script>
